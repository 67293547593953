import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { ConfirmToast } from 'react-confirm-toast';
import DataTable, { TableColumn } from 'react-data-table-component';
import toast from 'react-hot-toast';
import { Container } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import formObj from './formObj';

import { Link, useHistory } from 'react-router-dom';
import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  Row,
} from 'reactstrap';
import { Button, DaynamicSearchInputField, DynamicView } from 'src/components';
import { config } from 'src/config';
import { putUrlString } from 'src/helpers';
import * as url from 'src/helpers/url_helper';
import {
  useCreateByPostMutation,
  useDeleteByIdMutation,
  useGetDataQuery
} from 'src/rtk/features/common/crudApi';
import { actionColumnStyles, dataTableCustomStyles } from 'src/components/Common/FixedActionColumn';
import DeleteInformationModal from 'src/components/DeleteInformation/DeleteInformationModal';



export const RTMDTrainingList = () => {
  if (formObj?.title) {
    document.title = formObj?.title + ' | BCPS';
  }
  const history = useHistory();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>('');
  const [viewData, setViewData] = useState<any>({});
  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const [showAssignModal, setShowAssignModal] = useState<boolean>(false);
  const [assignAllChecked, setAssignAllChecked] = useState<boolean>(false);
  const [mandatoryAllChecked, setMandatoryAllChecked] =
    useState<boolean>(false);
  const [assignSubjectData, setAssignSubjectData] = useState<any>({});
  const [subjectMainArray, setSubjectMainArray] = useState<any>({});
  const [assignFormData, setAssignFormData] = useState<{}>({});
  const [setupCourseList, setSetupCourseList] = useState<[]>([]);
  const [setupSubjectList, setSetupSubjectList] = useState<[]>([]);
  const [searchSubject, setSearchSubject] = useState('');



  const {
    data: setupSubjectListData,
    isLoading: setupSubjectListLoading,
    isSuccess: setupSubjectListLoadingSuccess,
    error: setupSubjectListLoadingError,
  } = useGetDataQuery(url.SETUP_SUBJECT_INFO_LIST);

  // setSetupSubjectList(setupSubjectListData?.data || []);

  useEffect(() => {
    if (searchSubject.trim()) {
      let prevDataList = setupSubjectListData?.data || [];
      let newSetupSubjectList = prevDataList.filter((item: any) => {
        let subjectData = item?.subject_name || '';
        subjectData += ' ' + item?.subject_code || '';
        if (
          subjectData
            .toLowerCase()
            .indexOf(searchSubject.trim().toLowerCase()) !== -1
        ) {
          return item;
        } else {
          return false;
        }
      });

      setSetupSubjectList(newSetupSubjectList);
    } else {
      setSetupSubjectList(setupSubjectListData?.data || []);
      setAssignAllChecked(false);
      setMandatoryAllChecked(false);
    }
  }, [searchSubject, setupSubjectListData]);

  let courseData: any = {
    queryUrl: url.RTMD_GET_COURSE_LISTS,
    formData: {
      type: 'main-training',
      subject_id: 'no-need',
    },
  };

  const initialValues: any = {
    subjectMainArray: [],
  };

  const {
    handleSubmit,
    getFieldProps,
    resetForm,
    handleChange,
    values,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {
      let subject_ids = Object.values(values?.subjectMainArray);

      let formSubmitData = {
        queryUrl: url.RTMD_EXAM_TRAINING_INFO_SUBJECT_MAP,
        formData: {
          ...assignFormData,
          subject_ids,
        },
      };
      createByPost(formSubmitData);
    },
  });

  // const {
  //   data: setupMainTrainingListData,
  //   isLoading: mainTrainingListLoading,
  //   isSuccess: mainTrainingListLoadingSuccess,
  //   error: mainTrainingListLoadingError,
  // } = useGetByDataQuery(courseData);

  // let mainTrainingListData: any = setupMainTrainingListData?.data || [];

  // useEffect(() => {
  //   if (mainTrainingListData.length) {
  //     const mainTrainingList = setupMainTrainingListData?.data
  //       ? getSelectFormatedData(setupMainTrainingListData?.data, 'id', 'name')
  //       : [];

  //     let data = mainTrainingList[0];
  //     setMainTrainingId(data);
  //     getDataBy(generateQueryUrl());
  //     setMainTrainingList(mainTrainingList);
  //   }
  // }, [mainTrainingListData]);

  const allSubjectAssign = (
    e: any,
    type = 'assign',
    clsName = '.checkbox-subject-assign',
  ) => {
    let checkboxSubjectAssignAll = document.querySelectorAll(clsName);
    Object.keys(checkboxSubjectAssignAll).map((key: any) => {
      let item = checkboxSubjectAssignAll[key];
      let id = item.getAttribute('data-id');
      subjectAssign(id, e.target.checked, type);
    });
  };

  const findSubject = (id: any) => {
    let isFound = values['subjectMainArray'].findIndex(
      (sub: any) => id === sub.subject_id,
    );
    if (isFound !== -1) {
      return true;
    }

    return false;
  };

  const findRequired = (id: any) => {
    let isFound = values['subjectMainArray'].find(
      (sub: any) => id === sub.subject_id,
    );
    if (isFound?.required_ind) {
      return true;
    }
    return false;
  };

  const subjectAssign = (id: any, isChecked: boolean, type: string) => {
    var subjectArray: any = {};
    let sMainArray: any = values['subjectMainArray'];
    let getIndex = sMainArray.findIndex((sub: any) => id === sub.subject_id);

    if (type === 'assign') {
      if (isChecked) {
        subjectArray['required_ind'] = 0;
        subjectArray['subject_id'] = id;

        if (getIndex !== -1) {
          sMainArray[getIndex];
        } else {
          sMainArray.push(subjectArray);
        }
        // sMainArray[id] = subjectArray;
      } else {
        if (getIndex !== -1 && sMainArray[getIndex])
          // delete sMainArray[getIndex];
          sMainArray.splice(getIndex, 1);
      }
    } else if (type === 'required') {
      subjectArray['subject_id'] = id;
      subjectArray['required_ind'] = isChecked ? 1 : 0;

      if (getIndex !== -1) {
        sMainArray[getIndex] = subjectArray;
      } else {
        sMainArray.push(subjectArray);
      }
    }
    setFieldValue('subjectMainArray', sMainArray);
  };

  const [
    createByPost,
    {
      isSuccess: assignTrainingSubjectMapSuccess,
      isError: assignTrainingSubjectMapError,
      error,
    },
  ] = useCreateByPostMutation();
  const err: CustomError = error as CustomError;


  useEffect(() => {
    if (assignTrainingSubjectMapSuccess) {
      toast.success('Training Assign successfully');
      setShowAssignModal(false);
    }
    if (assignTrainingSubjectMapError) {
      toast.error(err?.message || 'Something went wrong');
    }
  }, [assignTrainingSubjectMapSuccess, assignTrainingSubjectMapError]);









  const generateQueryUrl = () => {
    let queryString = '?';
    if (formObj.listAPIUrl.includes("?")) {
      queryString = '&';
    }

    return `${formObj.listAPIUrl}${queryString}page=${page}&paginate_per_page=${limit}${search}`;
  };

  const {
    data: dataList,
    isLoading: dataListLoading,
    isSuccess: dataListLoadingSuccess,
    error: dataListLoadingError,
  } = useGetDataQuery(generateQueryUrl());
  const data: any = dataList?.data || [];

  useEffect(() => {
    if (dataListLoadingError) {
      toast.dismiss();
      toast.error('Data Loading error. Please try again later.');
    } else if (dataListLoading || !dataListLoadingSuccess) {
      toast.dismiss();
      // toast.loading('Data Loading...');
    } else {
      toast.dismiss();
      // toast.success('Data Load Successfully');
    }
  }, [dataListLoading, dataListLoadingSuccess, dataListLoadingError]);

  const [deleteByID, { isSuccess, isLoading, isError, error: deleteError }] =
    useDeleteByIdMutation();
  const errDelete: CustomError = error as CustomError;

  useEffect(() => {
    if (isSuccess) {
      toast.success(formObj.title + ' Deleted Successfully!');
    }
    if (isError) {
      toast.error(errDelete?.message || 'Something went wrong');
    }
  }, [isSuccess, isError]);

  let initialSearchValues: any = {};
  let inputSearchFields: any = [];
  let tableColumn: any = [];
  let viewColumn: any = [];
  let searchFormFilds = formObj?.formFields || {};

  Object.keys(searchFormFilds)
    .sort(function (a: any, b: any) {
      return (
        parseInt(searchFormFilds[a].positionInForm) -
        parseInt(searchFormFilds[b].positionInForm)
      );
    })
    .map((key) => {
      let searchFormField = searchFormFilds[key];

      if (searchFormField['viewCell']) {
        viewColumn.push({
          name: searchFormField['label'],
          viewCell: searchFormField['viewCell'],
        });
      }

      if (searchFormField['cell']) {
        tableColumn.push({
          ...searchFormField,
          name: <div>{searchFormField['label']}</div>,
          cell: searchFormField['cell'],
        });
      }

      if (formObj?.showSearchForm && searchFormFilds[key]['showInSearch']) {
        inputSearchFields.push(searchFormFilds[key]);

        if (
          searchFormField['type'] === 'checkbox' &&
          typeof searchFormField['default'] === 'string'
        ) {
          searchFormField['default'] = [searchFormField['default'].trim()];
        } else if (
          searchFormField['type'] === 'radio' &&
          typeof searchFormField['default'] === 'object'
        ) {
          searchFormField['default'] = searchFormField['default']
            .join(' ')
            .trim();
        } else if (searchFormField['type'] === 'date') {
          searchFormField['default'] =
            searchFormField?.options?.defaultDate || '';
        }

        initialSearchValues[searchFormField['name']] = '';
      }
    });

  let columns: TableColumn<any>[] = [
    // {
    //   name: '#',
    //   width: '50px',
    //   cell: (row, index: any) => (data.from ? data.from + index : index + 1),
    // },
    ...tableColumn,
  ];

  if (formObj?.showTableActionColumn) {
    columns.push({
      name: 'Action',
      width: '350px',
      style: actionColumnStyles,
      selector: (row: any) => row.id,
      cell: (row: any, index: any) => (
        <>
          <Button
            size="sm"
            color="dark"
            className="my-1 me-1"
            type="button"
            text={`<span class="fas fa-book" ></span> Assign`}
            onClick={() => {
              setAssignSubjectData(row);
              setShowAssignModal(true);
              resetForm();
              let sMainArray: any = [];

              // assigned_susbjects;
              Object.keys(row.assigned_susbjects).map((key: any) => {
                let subject = row.assigned_susbjects[key];
                var subjectArray: any = {};
                subjectArray['required_ind'] = subject.required_ind;
                subjectArray['subject_id'] = subject.subject_id;
                sMainArray.push(subjectArray);
              });
              setFieldValue('subjectMainArray', sMainArray);
              setAssignFormData({
                training_type: row.type,
                exam_training_id: row.id,
              });
            }}
          />
          {formObj?.showViewBtn && (
            <Button
              size="sm"
              className="my-1 me-1"
              text={`<span class="fas fa-eye" ></span> ${formObj.viewBtnTitle}`}
              color="primary"
              onClick={() => {
                setShowModal(true);
                // setViewData(row);
                setViewData(row);
              }}
            />
          )}

          {formObj?.showUpdateBtn && (
            <Button
              size="sm"
              className="my-1 me-1"
              text={`<span class="fas fa-edit" ></span> ${formObj.updateBtnTitle}`}
              color="info"
              onClick={() => history.push(`${formObj.updateUrl}${row.id}`)}
            />
          )}
          {formObj?.showDeleteBtn && (
            <Button
              size="sm"
              className="my-1"
              text={`<span class="fas fa-trash" ></span> ${formObj.deletBtnTitle}`}
              color="danger"
              onClick={() => {
                setDeleteModal(true);
                setDeleteId(row.id);
              }}
            />
            // <ConfirmToast
            //   asModal={true}
            //   childrenClassName="margin-top-10"
            //   customCancel="No"
            //   customConfirm="Yes"
            //   customFunction={() =>
            //     deleteByID(`${formObj.deleteAPIUrl}${row.id}`)
            //   }
            //   message={formObj.deletePromtMessage}
            //   position="top-right" //will be ignored cause asModal=true
            //   showCloseIcon={true}
            //   theme="lilac"
            // >
            //   <Button
            //     size="sm"
            //     className="my-1"
            //     text={`<span class="fas fa-trash" ></span> ${formObj.deletBtnTitle}`}
            //     color="danger"
            //   />
            // </ConfirmToast>
          )}
          {deleteModal && deleteId === row?.id ? (
            <DeleteInformationModal
              isOpen={deleteModal}
              toggle={() => setDeleteModal(!deleteModal)}
              id={`${formObj.deleteAPIUrl}${row.id}`}
              deleteInfomation={deleteByID}
            />
          ) : null}
        </>
      ),
    });
  }

  const {
    handleSubmit: handleSearchSubmit,
    getFieldProps: getSearchFieldProps,
    handleChange: searchHandleChange,
    handleBlur,
    values: searchValues,
    setValues: setSearchValues,
    setFieldValue: setSearchFieldValue,
    touched,
    errors,
    resetForm: resetSearchForm,
    setErrors,
  } = useFormik({
    initialValues: initialSearchValues,
    onSubmit: (searchValues) => {
      let s: string = '';

      console.log(searchValues);

      // Object.keys(searchValues).map((i: any) => {
      //   if (searchValues[i] === '') {
      //     delete searchValues[i];
      //   }
      // });

      if (searchValues?.name) {
        s = s + `&name=${searchValues.name}`;
        // delete searchValues.name;
      }

      if (Object.keys(searchValues).length > 0) {
        s = s + `&` + putUrlString('', searchValues).replaceAll('?', '');
      }

      setSearch(s);
      setPage('1');
      // setLimit(20);
    },
  });


  return (
    <>
      <div className="page-content">
        <Container fluid>
          {formObj.breadcrumbItemForList && (
            <BetterBreadcrumb
              title={formObj?.title + ' List'}
              breadcrumbItem={formObj.breadcrumbItemForList}
            />
          )}
          <Card>
            <CardHeader className=" ">
              <Row className="d-flex">
                {formObj?.showSearchForm && inputSearchFields.length > 0 && (
                  <>
                    <Col className="d-flex d-md-block  d-sm-none justify-content-start align-items-center">
                      <Form
                        className="custom-form w-100"
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSearchSubmit();
                        }}
                        onReset={(e) => {
                          e.preventDefault();
                          resetSearchForm();
                        }}
                      >
                        <div className="row">
                          {inputSearchFields.length > 0 &&
                            inputSearchFields.map((searchField: any, key: any) => {
                              if (
                                !['hidden', 'submit'].includes(searchField['type'])
                              ) {
                                searchField['isRequired'] = false;
                                searchField['default'] = '';

                                if (
                                  ['radio', 'checkbox'].includes(
                                    searchField['type'],
                                  )
                                ) {
                                  searchField['type'] = 'select';
                                }

                                if (
                                  searchField['dependField'] &&
                                  searchField['dependValue'] &&
                                  searchField['condition']
                                ) {
                                  let isMatch = true;
                                  switch (searchField['condition']) {
                                    case '===':
                                      isMatch =
                                        searchValues[searchField['dependField']] ===
                                        searchField['dependValue'];
                                      break;

                                    case '!==':
                                      isMatch =
                                        searchValues[searchField['dependField']] !==
                                        searchField['dependValue'];
                                      break;

                                    case 'includes':
                                      isMatch = searchField['dependValue'].includes(
                                        searchValues[searchField['dependField']],
                                      );
                                      break;

                                    default:
                                      break;
                                  }
                                  if (!isMatch) {
                                    if (
                                      searchValues[searchField['name']] !==
                                      searchField['default']
                                    ) {
                                      setSearchFieldValue(
                                        searchField['name'],
                                        searchField['default'],
                                      );
                                    }
                                    return <></>;
                                  }
                                }

                                return (
                                  <Col
                                    md={'6'}
                                    lg={'6'}
                                    xl={'4'}
                                    xxl={''}
                                    key={searchField['name'] + key}
                                  >
                                    <FormGroup
                                      // key={searchField['name'] + key}
                                      className="mb-3"
                                    >
                                      <>
                                        {['select'].includes(
                                          searchField['type'],
                                        ) && (
                                            <DaynamicSearchInputField
                                              label={searchField['label']}
                                              name={searchField['name']}
                                              type={searchField['type']}
                                              placeholder={'Select'}
                                              options={searchField['data']}
                                              dataApi={searchField['dataApi']}
                                              value={
                                                searchValues[searchField['name']] !==
                                                  undefined
                                                  ? searchValues[searchField['name']]
                                                  : searchField['default']
                                              }
                                              isMulti={searchField['isMulti']}
                                              required={searchField['isRequired']}
                                              invalid={{ errors, touched }}
                                              onChange={(data: any) => {
                                                if (data === null) {
                                                  setSearchFieldValue(
                                                    searchField['name'],
                                                    '',
                                                  );
                                                } else if (
                                                  data &&
                                                  data?.length === undefined &&
                                                  data.value !== undefined
                                                ) {
                                                  setSearchFieldValue(
                                                    searchField['name'],
                                                    data.value,
                                                  );
                                                } else if (
                                                  data &&
                                                  data?.length !== undefined
                                                ) {
                                                  setSearchFieldValue(
                                                    searchField['name'],
                                                    data.map(
                                                      (item: any) => item.value,
                                                    ),
                                                  );
                                                }
                                              }}
                                            />
                                          )}

                                        {['date'].includes(searchField['type']) && (
                                          <>
                                            <DaynamicSearchInputField
                                              label={searchField['label']}
                                              name={searchField['name']}
                                              type={searchField['type']}
                                              placeholder={''}
                                              options={searchField['options']}
                                              invalid={{ errors, touched }}
                                              value={
                                                searchValues[searchField['name']] !==
                                                  undefined
                                                  ? searchValues[searchField['name']]
                                                  : searchField?.options
                                                    ?.defaultDate
                                              }
                                              required={searchField['isRequired']}
                                              setData={(data: any) => {
                                                if (
                                                  searchField?.options?.mode ===
                                                  'range'
                                                ) {
                                                  setSearchFieldValue(
                                                    searchField['name'],
                                                    data[0],
                                                  );
                                                  setSearchFieldValue(
                                                    searchField['name2'],
                                                    data[1],
                                                  );
                                                } else {
                                                  setSearchFieldValue(
                                                    searchField['name'],
                                                    data.join(',').trim(),
                                                  );
                                                }
                                              }}
                                            />
                                          </>
                                        )}

                                        {['radio', 'checkbox'].includes(
                                          searchField['type'],
                                        ) && (
                                            <DaynamicSearchInputField
                                              label={searchField['label']}
                                              name={searchField['name']}
                                              type={searchField['type']}
                                              placeholder={''}
                                              options={searchField['data']}
                                              dataApi={searchField['dataApi']}
                                              value={
                                                searchValues[searchField['name']] !==
                                                  undefined
                                                  ? searchValues[searchField['name']]
                                                  : searchField['default']
                                              }
                                              isMulti={searchField['isMulti']}
                                              required={searchField['isRequired']}
                                              invalid={{ errors, touched }}
                                              onChange={(data: any) => {
                                                let selectedValue =
                                                  data.target.value.toString();
                                                if (
                                                  searchField['type'] === 'checkbox'
                                                ) {
                                                  let isChecked = data.target.checked;
                                                  let oldValue =
                                                    values[searchField['name']];

                                                  let getIndex =
                                                    oldValue.indexOf(selectedValue);
                                                  if (isChecked && getIndex === -1) {
                                                    oldValue.push(selectedValue);
                                                  } else if (
                                                    !isChecked &&
                                                    getIndex > -1
                                                  ) {
                                                    oldValue.splice(getIndex, 1);
                                                  }

                                                  setSearchFieldValue(
                                                    searchField['name'],
                                                    oldValue,
                                                  );
                                                } else {
                                                  setSearchFieldValue(
                                                    searchField['name'],
                                                    selectedValue,
                                                  );
                                                }
                                              }}
                                            />
                                          )}

                                        {![
                                          'select',
                                          'radio',
                                          'checkbox',
                                          'date',
                                        ].includes(searchField['type']) && (
                                            <DaynamicSearchInputField
                                              label={searchField['label']}
                                              type={searchField['type']}
                                              placeholder={searchField['label']}
                                              required={searchField['isRequired']}
                                              invalid={{ errors, touched }}
                                              {...getSearchFieldProps(searchField['name'])}
                                            />
                                          )}
                                      </>
                                    </FormGroup>
                                  </Col>
                                );
                              }
                            })}
                        </div>
                      </Form>
                    </Col>

                    <Col lg={'auto'} md={'5'} sm={12} className="mt-4 pt-1">
                      <FormGroup className="btn-group btn-group-example ">
                        <ButtonGroup className="d-inline-block ">
                          <Button
                            color="danger"
                            type="reset"
                            text="Reset"
                            onClick={() => {
                              resetSearchForm();
                              handleSearchSubmit();
                            }}
                          />
                          <Button
                            type="button"
                            text="Search"
                            onClick={() => {
                              handleSearchSubmit();
                            }}
                          />
                          {formObj?.showCreateNewBtn && (
                            <Button
                              color="success"
                              type="button"
                              text={formObj.createNewBtnTitle || 'Create New'}
                              onClick={() => {
                                history.push(`${formObj.addUrl}`);
                                // handleSearchSubmit();
                              }}
                            />
                          )}
                        </ButtonGroup>
                      </FormGroup>
                    </Col>
                  </>
                )}
                {formObj?.addUrl &&
                  formObj?.showCreateNewBtn &&
                  inputSearchFields.length === 0 && (
                    <Col
                      md={
                        formObj?.showSearchForm && inputSearchFields.length > 0
                          ? ''
                          : 12
                      }
                      sm={12}
                      className="d-flex justify-content-end align-items-center"
                    >
                      <div className="mt-2">
                        <Link
                          to={formObj.addUrl}
                          className="btn btn-success waves-effect waves-light"
                        >
                          <i className="fa fa-plus me-2" />{' '}
                          {formObj.createNewBtnTitle || 'Create New'}
                        </Link>
                      </div>
                    </Col>
                  )}
              </Row>
            </CardHeader>
            <CardBody>
              {data?.total !== undefined && columns.length > 0 && (
                <DataTable
                  columns={columns}
                  data={data?.data ? data.data : []}
                  pagination
                  paginationServer
                  customStyles={dataTableCustomStyles}
                  onChangePage={(page: number) => {
                    setPage(page);
                  }}
                  onChangeRowsPerPage={(newPerPage: number, page: number) => {
                    setLimit(newPerPage);
                    setPage(page);
                  }}
                  progressPending={dataListLoading}
                  paginationTotalRows={data?.total}
                  expandOnRowClicked
                  highlightOnHover
                  paginationRowsPerPageOptions={config.paginationRowsPerPageOptions}
                  persistTableHead={true}
                />
              )}

              {/* {data?.total === undefined && columns.length > 0 && (
            <DataTable
              columns={columns}
              data={data}
              pagination
              progressPending={dataListLoading}
              paginationTotalRows={data?.total}
              highlightOnHover
              paginationRowsPerPageOptions={config.paginationRowsPerPageOptions}
            />
          )} */}
            </CardBody>
          </Card>
          <Modal
            onOpened={function () { }}
            isOpen={showModal}
            scrollable={false}
            size="lg"
            toggle={() => {
              document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Details Info
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => {
                  setShowModal(false);
                }}
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <DynamicView viewColumn={viewColumn} viewData={viewData} />
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                text="Close"
                className="btn btn-danger"
                onClick={() => {
                  setShowModal(!showModal);
                }}
              ></Button>
            </div>
          </Modal>

          <Modal
            onOpened={function () {
              toast.dismiss();
            }}
            isOpen={showAssignModal}
            scrollable={false}
            size="xl"
            toggle={() => {
              document.body.classList.add('no_padding');
            }}
            backdrop={'static'}
            modalTransition={{ timeout: 0 }}
            backdropTransition={{ timeout: 0 }}
          >
            <Form
              autoComplete="off"
              className="custom-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  Subject Assign for {assignSubjectData.name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => {
                    setShowAssignModal(!showAssignModal);
                  }}
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body py-0">
                <div className="row">
                  <div
                    className="col-12 table-responsive p-0"
                    style={{ maxHeight: '70vh', overflow: 'auto' }}
                  >
                    <table className="table table-sm  table-striped table-bordered table-hover">
                      <thead className="bg-light fixed">
                        <tr className="text-center text-dark font-size-16 fw-bold align-top">
                          <td>SL#</td>
                          <td>
                            Subject List
                            <Input
                              className="form-control form-control-sm"
                              placeholder="Search by subject name or code"
                              type="search"
                              defaultValue=""
                              onChange={(e: any) => {
                                setSearchSubject(e.target.value);
                              }}
                            />
                          </td>
                          <td>
                            <div className="text-center ">
                              <div className=" ">
                                <div className="mb-2">Assign All? </div>
                                <div className="">
                                  <Input
                                    type="checkbox"
                                    id={'assign-switch-all'}
                                    switch="success"
                                    checked={assignAllChecked}
                                    onChange={(e) => {
                                      allSubjectAssign(e, 'assign');
                                      setAssignAllChecked(e.target.checked);
                                    }}
                                  />
                                  <Label
                                    htmlFor={'assign-switch-all'}
                                    data-on-label="Yes"
                                    data-off-label="No"
                                  ></Label>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="text-center ">
                              <div className=" ">
                                <div className="mb-2">Mandatory All? </div>
                                <div className="">
                                  <Input
                                    type="checkbox"
                                    id={'required-switch-all'}
                                    switch="success"
                                    checked={mandatoryAllChecked}
                                    onChange={(e) => {
                                      allSubjectAssign(e, 'required');
                                      setMandatoryAllChecked(e.target.checked);
                                      if (e.target.checked)
                                        setAssignAllChecked(e.target.checked);
                                    }}
                                  />
                                  <Label
                                    htmlFor={'required-switch-all'}
                                    data-on-label="Yes"
                                    data-off-label="No"
                                  ></Label>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {setupSubjectList.map((item: any, key: number) => {
                          return (
                            <tr key={item.id} className="">
                              <td className="text-center" style={{ width: '50px' }}>
                                <span
                                  style={{ width: '20px', height: '20px' }}
                                  className="avatar-title rounded-circle bg-soft-info text-dark font-size-12 m-2"
                                >
                                  {key + 1}
                                </span>
                              </td>
                              <td style={{ width: '50%' }}>
                                <div className="font-size-16 fw-bold mt-1">
                                  {item.subject_name}{' '}
                                  <span className="text-muted font-size-12">
                                    (Code: {item.subject_code})
                                  </span>
                                </div>
                              </td>
                              <td className="text-center align-middle">
                                <Input
                                  className="checkbox-subject-assign"
                                  key={'assign-switch-key-' + item.id}
                                  type="checkbox"
                                  id={'assign-switch-' + item.id}
                                  data-id={item.id}
                                  switch="success"
                                  checked={findSubject(item.id)}
                                  onChange={(e) => {
                                    if (e.target.checked === false) {
                                      setAssignAllChecked(e.target.checked);
                                      setMandatoryAllChecked(e.target.checked);
                                    }
                                    subjectAssign(
                                      item.id,
                                      e.target.checked,
                                      'assign',
                                    );
                                  }}
                                />
                                <Label
                                  htmlFor={'assign-switch-' + item.id}
                                  data-on-label="Yes"
                                  data-off-label="No"
                                ></Label>
                              </td>
                              <td className="text-center">
                                <Input
                                  key={'required-switch-key-' + item.id}
                                  className="checkbox-subject-required"
                                  type="checkbox"
                                  id={'required-switch-' + item.id}
                                  data-id={item.id}
                                  switch="success"
                                  // disabled={subjectMainArray[item.id] === undefined}
                                  checked={findRequired(item.id)}
                                  onChange={(e) => {
                                    if (e.target.checked === false)
                                      setMandatoryAllChecked(e.target.checked);
                                    subjectAssign(
                                      item.id,
                                      e.target.checked,
                                      'required',
                                    );
                                  }}
                                // disabled={true}
                                />
                                <Label
                                  htmlFor={'required-switch-' + item.id}
                                  data-on-label="Yes"
                                  data-off-label="No"
                                ></Label>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <div className="text-center">
                  <Button
                    type="submit"
                    text="Save"
                    className="btn btn-success"
                  ></Button>
                </div>
              </div>
            </Form>
          </Modal>
        </Container>
      </div>
    </>
  );
};
