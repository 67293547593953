import { useFormik } from 'formik';
import moment from 'moment';
import { useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Button, Container, Modal, ModalBody, ModalHeader } from 'reactstrap';
import BetterBreadcrumb from 'src/components/Common/BetterBreadCrumb';
import { actionColumnStyles, dataTableCustomStyles } from 'src/components/Common/FixedActionColumn';
import { useGetTrainingAllowanceListForTraineeQuery, useGetTrainingAllowanceNoticeQuery } from 'src/rtk/features/trainingAllowance/trainingAllowanceApi';
import loader from '../../assets/images/loader.gif';
import AllowanceForm from './AllowanceForm';
import { TrainingAllowancePDF } from './TrainingAllowancePDF';


const TrainingAllowanceListForTrainee = () => {
  const [modalShow, setModalShow] = useState(false);
  const [rowStatus, setRowStatus] = useState('');
  const [allowanceFormId, setAllowanceFormId] = useState('');
  const [showAllowanceReport, setShowAllowanceReport] = useState<boolean>(false);

  const [page, setPage] = useState<any>(1);
  const [limit, setLimit] = useState<any>(10);
  const [search, setSearch] = useState('');

  const generateQueryUrl = () => {
    return `page=${page}&paginate_per_page=${limit}${search}`;
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerRowsChange = (newPerPage: number, page: number) => {
    setLimit(newPerPage);
    setPage(page);
  };

  interface SearchFields {
    search?: string;
    bmdc_registration_no?: string;
    institute_name?: string;
    mobile?: string;
    approval_status?: string;
    subject_name?: string;
  }

  const initialValues: SearchFields = {
    search: '',
    bmdc_registration_no: '',
    institute_name: '',
    mobile: '',
    approval_status: '',
    subject_name: '',
  };

  const {
    data: allowanceList,
    isSuccess,
    isLoading,
  } = useGetTrainingAllowanceListForTraineeQuery(generateQueryUrl());

  const {
    data: noticeData,
    isSuccess: isNoticeSuccess,
    isLoading: isNoticeSuccessLoading,
  } = useGetTrainingAllowanceNoticeQuery();
  console.log(noticeData)
  const {
    handleSubmit,
    getFieldProps,
    setFieldValue,
    handleChange,
    values,
    touched,
    errors,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues,

    onSubmit: (values) => {
      let s: string = '';

      if (values.search !== '') {
        s = s + `&search=${values.search}`;
      }

      if (values.bmdc_registration_no !== '') {
        s = s + `&bmdc_registration_no=${values.bmdc_registration_no}`;
      }

      if (values.institute_name !== '') {
        s = s + `&institute_name=${values.institute_name}`;
      }

      if (values.mobile !== '') {
        s = s + `&mobile=${values.mobile}`;
      }

      if (values.subject_name !== '') {
        s = s + `&subject_name=${values.subject_name}`;
      }

      if (values.approval_status !== '') {
        s = s + `&approval_status=${values.approval_status}`;
      }

      setSearch(s);
      setPage('1');
    },
  });

  const columns: TableColumn<any>[] = [
    {
      name: <div>Sl. No.</div>,
      cell: (row, index) => <strong>{index + 1}</strong>,
      width: '60px',
    },

    {
      name: <div>Name of the Trainee</div>,
      selector: (row) => row?.registration?.personal_info?.full_name,
      sortable: false,
      wrap: true,
      width: '130px',
    },
    {
      name: <div>BCPS Reg. No.</div>,
      selector: (row) => row?.registration?.bcps_registration_no,
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>BMDC Reg. No.</div>,
      selector: (row) => row?.registration?.bmdc_registration_no,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Present Working Place</div>,
      selector: (row) => row?.registration?.current_session_and_supervisor_info?.supervisor?.institute_name,
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>Mobile Number</div>,
      selector: (row) => row?.registration?.personal_info?.mobile,
      sortable: false,
      wrap: true,
      width: '110px',
    },
    {
      name: <div>Email</div>,
      selector: (row) => row?.registration?.personal_info?.email,
      sortable: false,
      wrap: true,
      width: '130px',
    },
    {
      name: <div>Specialty</div>,
      selector: (row) => row?.registration?.subject?.subject_name,
      sortable: false,
      wrap: true,
    },
    {
      name: <div>Current Duration of FCPS Part-II</div>,
      selector: (row) => moment(row?.registration?.current_session_and_supervisor_info?.session_start_date).format('DD-MMM-YYYY') + ' to ' + moment(row?.registration?.current_session_and_supervisor_info?.session_end_date).format('DD-MMM-YYYY'),
      sortable: false,
      wrap: true,
      width: '160px',
    },
    {
      name: <div>Status</div>,
      width: '100px',
      selector: (row) => row?.accounts_approval_status,
      cell: (row: any) => {
        switch (true) {
          case row?.accounts_approval_status === 'Approve':
            return (
              <div className="badge rounded-pill d-inline-block badge-approved font-size-12 fw-medium">
                {'Eligible'}
              </div>
            );
          case [row?.accounts_approval_status, row?.admin_approval_status, row?.approval_status].includes('Reject'):
            return (
              <div className="badge rounded-pill d-inline-block badge-pending font-size-12 fw-medium">
                {'Not Eligible'}
              </div>
            );
          default:
            return (
              <div className="badge rounded-pill d-inline-block badge-draft font-size-12 fw-medium">
                {'Pending'}
              </div>
            );
        }
      },
      sortable: false,
      wrap: true,
    },

    {
      name: 'Action',
      width: '150px',
      style: actionColumnStyles,
      cell: (row) => (
        <>
          <div className="d-flex gap-1 my-1">
            {/* <Button
              className="btn btn-sm btn-info"
              onClick={() => setAllowanceId(row?.id)}
            >
              <span className="fas fa-eye"></span> Allowance Form
            </Button> */}

            <Button
              className="btn btn-sm btn-success"
              onClick={(e) => {
                setShowAllowanceReport(true);
                setAllowanceFormId(row?.id);
              }}
              disabled={row?.accounts_approval_status !== 'Approve'}
            >
              <span className="fas fa-eye"></span> Allowance
            </Button>
          </div>
          {/* {allowanceId === row?.id ? (
            <TrainingAllowanceApplicationPDF data={row} setAllowanceId={setAllowanceId} />
          ) : null} */}
          {showAllowanceReport && allowanceFormId === row?.id ? (
            <TrainingAllowancePDF data={row} setShowAllowanceReport={setShowAllowanceReport} setAllowanceFormId={setAllowanceFormId} />
          ) : null}
        </>
      ),
    },
  ];

  return (
    <div className="page-content">
      {isLoading ? (
        <div className="overlay">
          <img src={loader} alt="Loading" height={100} width={300} />
        </div>
      ) : null}
      <Container fluid>
        <div className='page-breadcrumb-wrapper'>
          <BetterBreadcrumb
            breadcrumbItem={[{ link: '/', name: 'Training Allowance' }]}
            title={'Training Allowance List'}
          />
          <div>
            <a
              type='button'
              rel='noreferrer'
              className='btn btn-link text-decoration-underline me-2'
              href={'/documents/Instructions for Training Allowance Application.pdf'}
              target='_blank'
            >
              Instructions
            </a>
            <Button color="primary" onClick={() => setModalShow(true)} disabled={noticeData?.data === null}>
              Allowance Entry
            </Button>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="card">
            <div className="card-body">
              <DataTable
                columns={columns}
                data={allowanceList?.data!}
                customStyles={dataTableCustomStyles}
                pagination
                paginationServer
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={isLoading}
                expandOnRowClicked
                responsive
                persistTableHead={true}
              />
            </div>
          </div>
        </div>
      </Container>

      <Modal isOpen={modalShow} size={'xl'} toggle={() => setModalShow(!modalShow)}>
        <ModalHeader toggle={() => setModalShow(!modalShow)}>Allowance Form</ModalHeader>
        <ModalBody>
          <AllowanceForm setModalShow={setModalShow} />
        </ModalBody>
      </Modal>

    </div>
  );
};

export default TrainingAllowanceListForTrainee;
